<template>
    <ASelect
        v-model:value="valueState"
        mode="multiple"
        placeholder="Pilih Fungsi"
        allow-clear
        show-search
        not-found-content="Tidak ditemukan"
        option-filter-prop="label"
        option-label-prop="label"
        @search="onSearch"
        @blur="() => (state.search = null)"
        @select="() => (state.search = null)"
        v-bind="$attrs"
        :loading="state.loading"
        :show-arrow="true"
        style="width:300px">
        <ASelectOption v-for="item in state.data" :key="item.function" :label="item.function">
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="highlight(item.function)"></span>
            <!--eslint-enable-->
        </ASelectOption>
    </ASelect>
</template>

<script>
import { onMounted, reactive } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'

export default {
    props: {
        value: {
            type: [Array, Number, Object, String],
            default: null,
        },
    },
    emits: ['update:value'],
    setup(props) {
        const state = reactive({
            loading: false,
            endpoint: '/api/filter/synchron-function-curah',
            data: [],
            search: null,
        })

        const fetchData = (q = null) => {
            state.loading = true
            apiClient.get(state.endpoint, { params: { q } })
                .then(({ data }) => {
                    state.data = data
                })
                .finally(() => {
                    state.loading = false
                })
        }

        const onSearch = debounce(value => {
            state.loading = true
            state.search = value
            fetchData(value)
        }, 300)

        const highlight = value => {
            return value.replace(new RegExp(state.search, 'gi'), match => {
                return `<span style="background-color: yellow;">${match}</span>`
            })
        }

        onMounted(() => {
            fetchData()
        })

        return {
            valueState: useVModel(props, 'value'),
            highlight,
            onSearch,
            state,
        }
    },
}
</script>